import { Form, Input, Select } from "antd";
import { useWatch } from "antd/es/form/Form";
import { max, min, required } from "../../helpers/FormRuleHelper";
import RacialSelect from "./RacialSelect";

const OptionalRacialForm = () => {
  const type = useWatch("type");

  return (
    <>
      <Form.Item
        label="Oluşturulacak Kaydı Seçiniz"
        name="type"
        rules={[required]}
        initialValue="specie"
      >
        <Select
          options={[
            { label: "Tür Oluştur", value: "specie" },
            { label: "Irk Oluştur", value: "racial" },
          ]}
        />
      </Form.Item>
      {type !== "specie" && (
        <Form.Item label="Tür Seç" name="specie" rules={[required]}>
          <RacialSelect />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label={(type === "specie" ? "Tür" : "Irk") + " Adı"}
        rules={[required, min(2), max(255)]}
      >
        <Input
          placeholder={(type === "specie" ? "Tür" : "Irk") + " adı giriniz"}
        />
      </Form.Item>
    </>
  );
};

export default OptionalRacialForm;
