import { Button, Form, InputNumber, Modal, Typography } from "antd";
import { CustomerCrudApi, GeneralApi } from "../../client/Api";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getCustomerCPColumns,
  getCustomerCPFormItems,
} from "../../components/hook/CustomerCPComponent";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { ArrowsAltOutlined, CalculatorOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { required } from "../../helpers/FormRuleHelper";
import dayjs from "dayjs";
import BKDatePicker from "../../components/common/BKDatePicker";
import { useForm } from "antd/es/form/Form";

const { Text, Title, Paragraph } = Typography;

const CustomerCrudPage = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [openExtractModal, setOpenExtractModal] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);

  const onCancel = () => {
    form.resetFields();
    setOperationLoading(false);
    setOpenExtractModal(false);
  };

  return (
    <>
      <CrudTable
        entityLabel="Cari"
        api={CustomerCrudApi}
        columns={getCustomerCPColumns()}
        addFormItems={getCustomerCPFormItems()}
        editFormItems={getCustomerCPFormItems(true)}
        extraRowProcess={(row) => (
          <>
            <CrudTableProcessButton
              icon={<CalculatorOutlined />}
              tooltipText="Cari Ekstresi Oluştur"
              onClick={() => {
                setOpenExtractModal(true);
                form.setFieldsValue({ customerId: row.id });
              }}
            />
            <CrudTableProcessButton
              icon={<ArrowsAltOutlined />}
              tooltipText="Cari Hareketleri"
              onClick={() => navigate("/cari-detay/" + row.id)}
            />
          </>
        )}
        expandable={{
          expandedRowRender: (record: any) => (
            <>
              <Title level={5}>Adres</Title>
              <Text>{record.address}</Text>
              <Title level={5}>Not</Title>
              <Paragraph style={{ width: "70vw" }}>{record.note}</Paragraph>
            </>
          ),
        }}
      />
      <Modal
        open={openExtractModal}
        title="Cari Ekstresi Oluştur"
        onCancel={onCancel}
        footer={<></>}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            setOperationLoading(true);
            GeneralApi.getExtractOfCustomer(values)
              .then((response) => {
                const link = document.createElement("a");
                link.download = response["fileName"];
                link.href = `data:application/pdf;base64,${response["file"]}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              })
              .finally(() => setOperationLoading(false));
          }}
        >
          <Form.Item
            hidden
            name="customerId"
            label="Cari ID"
            rules={[required]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="startDate"
            label="Başlangıç Tarihi"
            rules={[required]}
            initialValue={dayjs()}
          >
            <BKDatePicker showTime />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="Bitiş Tarihi"
            rules={[required]}
            initialValue={dayjs()}
          >
            <BKDatePicker showTime />
          </Form.Item>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            loading={operationLoading}
          >
            Cari Ekstresi Hesapla
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerCrudPage;
