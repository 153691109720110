import { createBrowserRouter } from "react-router-dom";
import NotFoundPage from "./pages/common/NotFoundPage";
import LoginPage from "./pages/common/LoginPage";
import RegisterPage from "./pages/common/RegisterPage";
import ForgotPasswordPage from "./pages/common/ForgotPasswordPage";
import AuthLayout from "./components/layouts/AuthLayout";
import SiderLayout from "./components/layouts/SiderLayout";
import ClinicPage from "./pages/ClinicPage";
import CustomerCrudPage from "./pages/crud/CustomerCrudPage";
import Dashboard from "./pages/Dashboard";
import ProductCrudPage from "./pages/crud/ProductCrudPage";
import CategoryCrudPage from "./pages/crud/CategoryCrudPage";
import BrandCrudPage from "./pages/crud/BrandCrudPage";
import StockCrudPage from "./pages/crud/StockCrudPage";
import VaultCrudPage from "./pages/crud/VaultCrudPage";
import VaultItemCrudPage from "./pages/crud/VaultItemCrudPage";
import CustomerItemCrudPage from "./pages/crud/CustomerItemCrudPage";
import InvoiceCrudPage from "./pages/crud/InvoiceCrudPage";
import InvoiceDetailPage from "./pages/InvoiceDetailPage";
import NotificationCrudPage from "./pages/crud/NotificationCrudPage";
import SoonExpireCrudPage from "./pages/crud/SoonExpireCrudPage";
import CriticalStockCrudPage from "./pages/crud/CriticalStockCrudPage";
import PetCrudPage from "./pages/crud/PetCrudPage";
import VaccineCrudPage from "./pages/crud/VaccineCrudPage";
import VaccineCalendarCrudPage from "./pages/crud/VaccineCalendarCrudPage";
import RacialCrudPage from "./pages/crud/RacialCrudPage";

export const loginPath = "/giris-yap";
export const successPath = "/";

const getPrivateRoutes = () => [
  { path: "/", element: <Dashboard /> },
  { path: "/klinik-degistir", element: <ClinicPage /> },
  { path: "/cariler", element: <CustomerCrudPage /> },
  { path: "/cari-detay/:id", element: <CustomerItemCrudPage /> },
  { path: "/urunler", element: <ProductCrudPage /> },
  { path: "/kritik-stoklar", element: <CriticalStockCrudPage /> },
  { path: "/skt-uyarilari", element: <SoonExpireCrudPage /> },
  { path: "/stok-detay/:id", element: <StockCrudPage /> },
  { path: "/kategoriler", element: <CategoryCrudPage /> },
  { path: "/markalar", element: <BrandCrudPage /> },
  { path: "/kasalar", element: <VaultCrudPage /> },
  { path: "/kasa-detay/:id", element: <VaultItemCrudPage /> },
  { path: "/faturalar", element: <InvoiceCrudPage /> },
  { path: "/fatura-olustur", element: <InvoiceDetailPage /> },
  { path: "/fatura-duzenle/:id", element: <InvoiceDetailPage /> },
  { path: "/senet-bildirimleri", element: <NotificationCrudPage /> },
  { path: "/hasta-listesi", element: <PetCrudPage /> },
  { path: "/asi-takvimi", element: <VaccineCalendarCrudPage /> },
  { path: "/asi-listesi", element: <VaccineCrudPage /> },
  { path: "/tur-irk-listesi", element: <RacialCrudPage /> },
];

const getAuthRoutes = () => [
  { path: "/giris-yap", element: <LoginPage /> },
  { path: "/kayit-ol", element: <RegisterPage /> },
  { path: "/sifremi-unuttum", element: <ForgotPasswordPage /> },
];

export const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: getAuthRoutes(),
  },
  {
    element: <SiderLayout />,
    children: getPrivateRoutes(),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
