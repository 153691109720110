import CrudTable from "../../components/common/crud/CrudTable";
import { Typography } from "antd";
import { getSoonExpireCPColumns } from "../../components/hook/SoonExpireCPComponent";
import { getCrudApi } from "../../client/CrudApi";

const { Text } = Typography;

const SoonExpireCrudPage = () => {
  return (
    <CrudTable
      api={{}}
      getAll={getCrudApi("/soon-expire").getAll}
      hideAddProcess
      hideDefaultRowProceses
      hideSearchBar
      columns={getSoonExpireCPColumns()}
      customTableTitle={() => (
        <Text style={{ fontWeight: 600 }}>SKT Uyarıları</Text>
      )}
    />
  );
};

export default SoonExpireCrudPage;
