import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { colors } from "../theme";
import BKDatePicker from "../components/common/BKDatePicker";
import { useContext, useEffect, useState } from "react";
import { ConstantContext } from "../context";
import { useForm, useWatch } from "antd/es/form/Form";
import { required } from "../helpers/FormRuleHelper";
import SearchSelect from "../components/common/SearchSelect";
import { CustomerCrudApi, InvoiceCrudApi, UserCrudApi } from "../client/Api";
import { getOptionsFromEntities } from "../helpers/UtilHelper";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import InvoiceFormListItem from "../components/form/InvoiceFormListItem";
import LimitedText from "../components/common/LimitedText";
import { EyeOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const InvoiceDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const type = useWatch("type", form);
  const invoiceItems = useWatch("invoiceItems", form);
  const constants = useContext(ConstantContext);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    InvoiceCrudApi.get(parseInt(id))
      .then((response) => {
        const invoiceItems = response.invoiceItems.map((invoiceItem: any) => {
          return {
            ...invoiceItem,
            selectStock: invoiceItem.serialNo,
            expirationDate: invoiceItem.expirationDate
              ? dayjs(invoiceItem.expirationDate)
              : undefined,
            product: {
              value: invoiceItem.product["@id"],
              entity: invoiceItem.product,
              label: (
                <Row justify="space-between">
                  <LimitedText text={invoiceItem.product.name} />
                  <Tooltip
                    title={
                      <Row>
                        <Col span={24}>
                          Ortalama Alış Fiyatı:{" "}
                          {invoiceItem.product.averageBuyPrice ?? "0.00"}₺
                        </Col>
                        <Col span={24}>
                          Son Alış Fiyatı:{" "}
                          {invoiceItem.product.lastBuyPrice ?? "0.00"}₺
                        </Col>
                        <Col span={24}>
                          Satış Fiyatı:{" "}
                          {invoiceItem.product.sellPrice ?? "0.00"}₺
                        </Col>
                        <Col span={24}>
                          Satış Fiyatı (2):{" "}
                          {invoiceItem.product.sellPrice2 ?? "0.00"}₺
                        </Col>
                      </Row>
                    }
                  >
                    <EyeOutlined style={{ cursor: "pointer" }} />
                  </Tooltip>
                </Row>
              ),
            },
          };
        });

        const values = {
          ...response,
          date: dayjs(response.date),
          employee: response.employee
            ? {
                label: response.employee.fullName,
                value: response.employee["@id"],
              }
            : undefined,
          customer: response.customer
            ? {
                label: response.customer.name,
                value: response.customer["@id"],
              }
            : undefined,
          invoiceItems: invoiceItems,
        };
        form.setFieldsValue(values);
      })
      .finally(() => setLoading(false));
  }, [id, form]);

  useEffect(() => {
    let totalLowerAmount = 0.0;
    let totalDiscount = 0.0;
    let totalTaxAmount = 0.0;
    let totalAmount = 0.0;

    invoiceItems?.forEach((invoiceItem: any, index: number) => {
      if (!invoiceItem) return;
      const amount = invoiceItem.price * invoiceItem.quantity;
      const rowAmount = amount - invoiceItem.discount;
      const taxAmount = rowAmount * (invoiceItem.taxRate / 100);

      form.setFieldValue(
        ["invoiceItems", index, "taxAmount"],
        taxAmount.toString()
      );
      form.setFieldValue(
        ["invoiceItems", index, "rowAmount"],
        rowAmount.toString()
      );

      totalLowerAmount += amount;
      totalDiscount += parseFloat(invoiceItem.discount);
      totalTaxAmount += taxAmount;
      totalAmount += rowAmount + taxAmount;
    });

    form.setFieldValue("totalLowerAmount", totalLowerAmount.toFixed(3));
    form.setFieldValue("totalDiscount", totalDiscount.toFixed(3));
    form.setFieldValue("totalTaxAmount", totalTaxAmount.toFixed(3));
    form.setFieldValue("totalAmount", totalAmount.toFixed(3));
  }, [invoiceItems, form]);

  return (
    <div style={{ width: "100%", backgroundColor: colors.white, padding: 32 }}>
      {loading ? (
        <Row justify="center" align="middle">
          <Spin />
        </Row>
      ) : (
        <>
          <Form layout="vertical" form={form}>
            {id && (
              <Form.Item name="id" hidden rules={[required]}>
                <Input value={id} />
              </Form.Item>
            )}
            <Row gutter={[32, 32]}>
              <Col span={4}>
                <Form.Item
                  label="Fatura No"
                  name="name"
                  style={{ width: "100%" }}
                  rules={[required]}
                  initialValue={"F" + dayjs().format("YYYYMMDD") + "01"}
                >
                  <Input size="large" placeholder="Fatura no giriniz" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Fatura Tarihi"
                  name="date"
                  style={{ width: "100%" }}
                  rules={[required]}
                  initialValue={dayjs()}
                >
                  <BKDatePicker showTime size="large" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Fatura Tipi"
                  name="type"
                  style={{ width: "100%" }}
                  rules={[required]}
                  initialValue="sell"
                >
                  <Select
                    size="large"
                    placeholder="Fatura tipi seçiniz"
                    options={constants?.invoiceTypes}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="customer"
                  label={type === "buy" ? "Tedarikçi" : "Müşteri"}
                  style={{ width: "100%" }}
                >
                  <SearchSelect
                    size="large"
                    placeholder={
                      (type === "buy" ? "Tedarikçi" : "Müşteri") + " seçiniz"
                    }
                    searchAction={(searchValue, setOptions, setLoading) =>
                      CustomerCrudApi.getAll(1, 10, searchValue)
                        .then((response) => {
                          setOptions(
                            getOptionsFromEntities(response["hydra:member"])
                          );
                        })
                        .finally(() => setLoading(false))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="employee"
                  label="Personel"
                  style={{ width: "100%" }}
                >
                  <SearchSelect
                    size="large"
                    placeholder={"Personel seçiniz"}
                    searchAction={(searchValue, setOptions, setLoading) =>
                      UserCrudApi.getAll(1, 10, searchValue)
                        .then((response) => {
                          setOptions(
                            getOptionsFromEntities(
                              response["hydra:member"],
                              "fullName"
                            )
                          );
                        })
                        .finally(() => setLoading(false))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.List name="invoiceItems">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ name }, index) => (
                    <InvoiceFormListItem
                      key={index}
                      name={name}
                      type={type}
                      form={form}
                      remove={remove}
                    />
                  ))}
                  <Form.Item style={{ width: "100%" }}>
                    <Button
                      style={{ width: "100%" }}
                      size="large"
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Satır Ekle
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Row gutter={[8, 8]} align="middle">
              <Col span={12}>
                <Form.Item
                  label="Satış Notları"
                  name="note"
                  style={{ width: "100%" }}
                >
                  <TextArea
                    rows={4}
                    style={{ width: "100%" }}
                    placeholder="Satış notu giriniz"
                  />
                </Form.Item>
              </Col>
              <Col offset={2} span={8}>
                <Form.Item
                  labelCol={{ span: 11 }}
                  layout="horizontal"
                  label="Alt Tutar (₺)"
                  name="totalLowerAmount"
                  initialValue="0"
                >
                  <InputNumber
                    disabled
                    style={{ width: "100%" }}
                    size="large"
                    stringMode
                    precision={3}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 11 }}
                  layout="horizontal"
                  label="Toplam İskonto (₺)"
                  name="totalDiscount"
                  initialValue="0"
                >
                  <InputNumber
                    disabled
                    style={{ width: "100%" }}
                    size="large"
                    stringMode
                    precision={3}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 11 }}
                  layout="horizontal"
                  label="Toplam KDV (₺)"
                  name="totalTaxAmount"
                  initialValue="0"
                >
                  <InputNumber
                    disabled
                    style={{ width: "100%" }}
                    size="large"
                    stringMode
                    precision={3}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 11 }}
                  layout="horizontal"
                  label="Toplam Tutar (₺)"
                  name="totalAmount"
                  initialValue="0"
                >
                  <InputNumber
                    disabled
                    style={{ width: "100%" }}
                    size="large"
                    stringMode
                    precision={3}
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Button
            loading={actionLoading}
            size="large"
            type="primary"
            onClick={() => {
              form.validateFields().then((values) => {
                setActionLoading(true);
                if (id) {
                  const invoiceItems = values.invoiceItems.map(
                    (invoiceItem: any) => {
                      return {
                        ...invoiceItem,
                        //Direk edit seçilince Tarih + 00.00 olarak geliyor bu yüzden +3 eklememiz birşey ifade etmiyor
                        //Eğer datepicker'dan seçersek Tarih-1 + 21:00 olarak geliyor mecburen +3 eklememiz lazım
                        expirationDate: dayjs(invoiceItem.expirationDate).add(
                          3,
                          "h"
                        ),
                        product:
                          typeof invoiceItem.product === "object"
                            ? invoiceItem.product.value
                            : invoiceItem.product,
                      };
                    }
                  );

                  let customer = null;
                  if (values.customer) {
                    customer =
                      typeof values.customer === "object"
                        ? values.customer.value
                        : values.customer;
                  }

                  let employee = null;
                  if (values.employee) {
                    employee =
                      typeof values.employee === "object"
                        ? values.employee.value
                        : values.employee;
                  }

                  InvoiceCrudApi.edit(values.id, {
                    ...values,
                    customer: customer,
                    employee: employee,
                    invoiceItems: invoiceItems,
                  })
                    .then(() => {
                      message.success("Fatura düzenlendi.");
                      navigate("/faturalar");
                    })
                    .finally(() => setActionLoading(false));
                } else {
                  //Direk edit seçilince Tarih + 00.00 olarak geliyor bu yüzden +3 eklememiz birşey ifade etmiyor
                  //Eğer datepicker'dan seçersek Tarih-1 + 21:00 olarak geliyor mecburen +3 eklememiz lazım
                  values.invoiceItems = values.invoiceItems.map(
                    (invoiceItem: any) => {
                      return {
                        ...invoiceItem,
                        expirationDate: dayjs(invoiceItem.expirationDate).add(
                          3,
                          "h"
                        ),
                      };
                    }
                  );
                  InvoiceCrudApi.create(values)
                    .then(() => {
                      message.success("Fatura oluşturuldu.");
                      navigate("/faturalar");
                    })
                    .finally(() => setActionLoading(false));
                }
              });
            }}
          >
            Faturayı {id ? "Düzenle" : "Kaydet"}
          </Button>
        </>
      )}
    </div>
  );
};

export default InvoiceDetailPage;
