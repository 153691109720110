import { Tooltip } from "antd";

interface ILimitedTextProps {
  text: any;
}

const LimitedText = (props: ILimitedTextProps) => {
  return props.text.length > 20 ? (
    <Tooltip title={props.text}>{props.text.substring(0, 20) + "..."}</Tooltip>
  ) : (
    props.text
  );
};

export default LimitedText;
