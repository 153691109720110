import { RacialCrudApi } from "../../client/Api";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getRacialCPColumns,
  getRacialCPFormItems,
} from "../../components/hook/RacialCPComponent";

const RacialCrudPage = () => {
  return (
    <CrudTable
      entityLabel="Tür/Irk"
      api={RacialCrudApi}
      columns={getRacialCPColumns()}
      addFormItems={getRacialCPFormItems()}
      editFormItems={getRacialCPFormItems(true)}
      setEditFields={(row: any) => ({
        ...row,
        type: row.specie ? "racial" : "specie",
      })}
    />
  );
};

export default RacialCrudPage;
