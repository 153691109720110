import { Form, Input, InputNumber, Select, Space, Tooltip } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import { getConstantValue } from "../../helpers/UtilHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";
import RacialSelect from "../form/RacialSelect";
import TableSearchFilter from "../common/TableSearchFilter";

export const getVaccineCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Hasta Türü",
      dataIndex: "racial",
      key: "racial.id",
      render: (value: any) => value.name,
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <RacialSelect
            style={{ width: 250 }}
            value={
              props.selectedKeys[0]
                ? "/api/racials/" + props.selectedKeys[0]
                : null
            }
            onSelect={(_: any, row: any) => props.setSelectedKeys([row.id])}
          />
        </TableSearchFilter>
      ),
    },
    { title: "Aşı Adı", dataIndex: "name", key: "name" },
    {
      title: (
        <Space>
          Ne Zaman Yapılacak?
          <Tooltip title="Aşının doğum tarihinden itibaren ne zaman yapılacağı">
            <QuestionCircleOutlined style={{ cursor: "pointer" }} />
          </Tooltip>
        </Space>
      ),
      dataIndex: "afterBirth",
      key: "afterBirth",
    },
    {
      title: (
        <Space>
          Yenileme
          <Tooltip title="Aşının kaç günde bir tekrarlanacağı">
            <QuestionCircleOutlined style={{ cursor: "pointer" }} />
          </Tooltip>
        </Space>
      ),
      dataIndex: "renewal",
      key: "renewal",
      filters: constants?.renewals,
      render: (value: any) => getConstantValue(value, constants?.renewals),
    },
    {
      title: "Zorunluluk Tipi",
      dataIndex: "requireOption",
      key: "requireOption",
      filters: constants?.requireOptions,
      render: (value: any) =>
        getConstantValue(value, constants?.requireOptions),
    },
  ];
};

export const getVaccineCPFormItems = (constants: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Aşı Adı"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Aşı adı giriniz" />
      </Form.Item>
      <Form.Item name="racial" label="Hasta Türü" rules={[required]}>
        <RacialSelect />
      </Form.Item>
      <Form.Item
        name="afterBirth"
        label="Ne Zaman Yapılacak"
        rules={[required]}
        initialValue={0}
        tooltip="Aşının doğum tarihinden itibaren ne zaman yapılacağı"
      >
        <InputNumber
          style={{ width: "100%" }}
          min={0}
          placeholder="Doğumdan sonra ne zaman yapılacağını giriniz"
        />
      </Form.Item>
      <Form.Item name="renewal" label="Yenileme" rules={[required]}>
        <Select placeholder="Yenileme seçiniz" options={constants?.renewals} />
      </Form.Item>
      <Form.Item
        name="requireOption"
        label="Zorunluluk Tipi"
        rules={[required]}
      >
        <Select
          placeholder="Zorunluluk tipi seçiniz"
          options={constants?.requireOptions}
        />
      </Form.Item>
    </>
  );
};
