import { useRef, useState } from "react";
import { NotificationCrudApi } from "../../client/Api";
import CrudTable, {
  ICrudTableRefMethods,
} from "../../components/common/crud/CrudTable";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { getNotificationCPColumns } from "../../components/hook/NotificationCPComponent";
import { CheckOutlined } from "@ant-design/icons";
import { Modal } from "antd";

const NotificationCrudPage = () => {
  const crudTableRef = useRef<ICrudTableRefMethods>(null);

  const [selectedId, setSelectedId] = useState();
  const [makeReadModalOpen, setMakeReadModalOpen] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);

  const onCancel = () => {
    setSelectedId(undefined);
    setOperationLoading(false);
    setMakeReadModalOpen(false);
  };

  return (
    <>
      <CrudTable
        ref={crudTableRef}
        entityLabel="Senet"
        api={NotificationCrudApi}
        columns={getNotificationCPColumns()}
        hideAddProcess
        hideEditProcess
        extraRowProcess={(row) =>
          !row.isRead && (
            <CrudTableProcessButton
              tooltipText="Okundu Yap"
              icon={<CheckOutlined />}
              onClick={() => {
                setSelectedId(row.id);
                setMakeReadModalOpen(true);
              }}
            />
          )
        }
      />
      <Modal
        open={makeReadModalOpen}
        onCancel={onCancel}
        title="Okundu Yap"
        okText="Okundu Yap"
        cancelText="Vazgeç"
        okButtonProps={{ loading: operationLoading }}
        onOk={() => {
          if (selectedId) {
            setOperationLoading(true);
            NotificationCrudApi.edit(selectedId, {
              id: selectedId,
              isRead: true,
            })
              .then(() => crudTableRef.current?.refreshData())
              .finally(() => onCancel());
          }
        }}
      >
        Senet bildirimini okundu yapmak istediğinize emin misiniz ? Bu işlem
        geri alınamaz.
      </Modal>
    </>
  );
};

export default NotificationCrudPage;
