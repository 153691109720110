import { api } from "./Axios";
import { getCrudApi } from "./CrudApi";

export const ClinicCrudApi = getCrudApi("clinics");
export const UserCrudApi = getCrudApi("users");
export const CustomerCrudApi = getCrudApi("customers");
export const ProductCrudApi = getCrudApi("products");
export const CategoryCrudApi = getCrudApi("categories");
export const BrandCrudApi = getCrudApi("brands");
export const VaultCrudApi = getCrudApi("vaults");
export const InvoiceCrudApi = getCrudApi("invoices");
export const NotificationCrudApi = getCrudApi("notifications");
export const PetCrudApi = getCrudApi("pets");
export const RacialCrudApi = getCrudApi("racials");
export const VaccineCrudApi = getCrudApi("vaccines");
export const VaccineCalenderCrudApi = getCrudApi("vaccine-calendars");

export const CouponApi = {
  use: (values: any) => {
    return api
      .post("coupons/use", values)
      .then((response: any) => response.data);
  },
};

export const GeneralApi = {
  getConstants: () => {
    return api.get("constants").then((response: any) => response.data);
  },
  getSoonCheckCount: () => {
    return api.get("soon-check-count").then((response: any) => response.data);
  },
  getCriticStockCount: () => {
    return api.get("critic-stock-count").then((response: any) => response.data);
  },
  getSoonExpiredCountCount: () => {
    return api.get("soon-expired-count").then((response: any) => response.data);
  },
  getExtractOfCustomer: (values: any) => {
    return api
      .post("extract-of-customer", values)
      .then((response: any) => response.data);
  },
};

export const AuthApi = {
  login: (phone: string, password: string) => {
    return api
      .post("login", { username: phone, password: password })
      .then((response: any) => response.data);
  },
  register: (values: any) => {
    return api.post("register", values).then((response: any) => response.data);
  },
  forgotPassword: (values: any) => {
    return api
      .post("forgot-password", values)
      .then((response: any) => response.data);
  },
  profileUpdate: (values: any) => {
    return api.put("profile", values).then((response: any) => response.data);
  },
  renewPassword: (values: any) => {
    return api
      .put("renew-password", values)
      .then((response: any) => response.data);
  },
  refreshToken: (refreshToken: string) => {
    return api
      .post("refresh-token", { refreshToken: refreshToken })
      .then((response: any) => response.data);
  },
  currentUser: () => {
    return api.get("current-user").then((response: any) => response.data);
  },
};
