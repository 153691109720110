import dayjs from "dayjs";
import { VaccineCalenderCrudApi } from "../../client/Api";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getVaccineCalendarCPColumns,
  getVaccineCalendarCPFormItems,
} from "../../components/hook/VaccineCalendarCPComponent";

const VaccineCalendarCrudPage = () => {
  return (
    <CrudTable
      customTableTitle={() => "Aşı Takvimi"}
      entityLabel="Aşı Takvimi"
      api={VaccineCalenderCrudApi}
      columns={getVaccineCalendarCPColumns()}
      addFormItems={getVaccineCalendarCPFormItems()}
      editFormItems={getVaccineCalendarCPFormItems(true)}
      setEditFields={(row) => {
        return {
          ...row,
          date: dayjs(row.date),
          doneDate: row.doneDate ? dayjs(row.doneDate) : undefined,
          pet: row.pet
            ? { label: row.pet.name, value: row.pet["@id"] }
            : undefined,
          vaccine: row.vaccine
            ? { label: row.vaccine.name, value: row.vaccine["@id"] }
            : undefined,
        };
      }}
      beforeEditOperation={(values: any) => {
        let pet = null;
        if (values.pet) {
          pet = typeof values.pet === "object" ? values.pet.value : values.pet;
        }

        let vaccine = null;
        if (values.vaccine) {
          vaccine =
            typeof values.vaccine === "object"
              ? values.vaccine.value
              : values.vaccine;
        }

        return { ...values, pet: pet, vaccine: vaccine };
      }}
    />
  );
};

export default VaccineCalendarCrudPage;
