import { DatePicker, Form, Input, TimeRangePickerProps } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import TableSearchFilter from "../common/TableSearchFilter";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const presets: TimeRangePickerProps["presets"] = [
  {
    label: "Bugün",
    value: [
      dayjs().set("h", 0).set("m", 0).set("s", 0),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Dün",
    value: [
      dayjs().add(-1, "d").set("h", 0).set("m", 0).set("s", 0),
      dayjs().add(-1, "d").set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Bu Hafta",
    value: [
      dayjs().startOf("week"),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Bu Ay",
    value: [
      dayjs().startOf("month"),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Bu Yıl",
    value: [
      dayjs().startOf("year"),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Son 7 Gün",
    value: [
      dayjs().add(-7, "d").set("h", 0).set("m", 0).set("s", 0),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Son 30 Gün (1 Ay)",
    value: [
      dayjs().add(-1, "month").set("h", 0).set("m", 0).set("s", 0),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Son 90 Gün (3 Ay)",
    value: [
      dayjs().add(-3, "month").set("h", 0).set("m", 0).set("s", 0),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Son 180 Gün (6 Ay)",
    value: [
      dayjs().add(-6, "month").set("h", 0).set("m", 0).set("s", 0),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Son 365 Gün (1 Yıl)",
    value: [
      dayjs().add(-1, "y").set("h", 0).set("m", 0).set("s", 0),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
];

export const getVaultCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Kasa Adı", dataIndex: "name", key: "name" },
    {
      title: "Bakiye",
      dataIndex: "balance",
      key: "balance",
      filterResetToDefaultFilteredValue: true,
      defaultFilteredValue: [
        dayjs().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
        dayjs().endOf("month").format("YYYY-MM-DDTHH:mm:ss"),
      ],
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <RangePicker
            showTime
            presets={presets}
            value={props.selectedKeys?.map((selectedKey: any) =>
              dayjs(selectedKey)
            )}
            onChange={(dates) => {
              if (dates === null) return;
              props.setSelectedKeys(
                dates.map((date: any) =>
                  dayjs(date).format("YYYY-MM-DDTHH:mm:ss")
                )
              );
            }}
          />
        </TableSearchFilter>
      ),
    },
  ];
};

export const getVaultCPFormItems = (isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Kasa Adı"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Kasa adı giriniz" />
      </Form.Item>
    </>
  );
};
