import { Form } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import SearchSelect from "../common/SearchSelect";
import { PetCrudApi, VaccineCrudApi } from "../../client/Api";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";
import { useEffect, useState } from "react";
import { useWatch } from "antd/es/form/Form";

const OptionalVaccineForm = () => {
  const form = Form.useFormInstance();
  const pet = useWatch("pet", form);

  const [specieId, setSpecieId] = useState(undefined);

  useEffect(() => {
    form.resetFields(["vaccine"]);
  }, [specieId, form]);

  return (
    <>
      <Form.Item name="pet" label="Hasta" rules={[required]}>
        <SearchSelect
          placeholder="Hasta seçiniz"
          onSelect={(_: any, option: any) =>
            setSpecieId(option.entity.racial.specie.id)
          }
          onClear={() => setSpecieId(undefined)}
          searchAction={(searchValue, setOptions, setLoading) =>
            PetCrudApi.getAll(1, 10, searchValue)
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
      <Form.Item name="vaccine" label="Aşı" rules={[required]}>
        <SearchSelect
          disabled={!Boolean(pet)}
          placeholder="Aşı seçiniz"
          searchAction={(searchValue, setOptions, setLoading) =>
            VaccineCrudApi.getAll(
              1,
              10,
              searchValue === " " ? undefined : searchValue,
              [],
              ["racial.id=[" + (specieId ?? "9999") + "]"]
            )
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
    </>
  );
};

export default OptionalVaccineForm;
