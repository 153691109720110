import Icon from "@ant-design/icons";

const PawIcon = (props: any) => (
  <Icon
    {...props}
    component={() => (
      <svg width="16" height="16" viewBox="0 0 512 512">
        <path fill="none" d="M0 0h512v512H0z" />
        <path
          fill="currentColor"
          d="M171.74 42.55c-24.42 9.45-42.53 43.32-42.53 77.98 0 72.47 77.19 107.91 121.3 55.14 54.35-63.81-4.73-163.05-78.77-133.12m49.63 29.93c28.35 18.12 26.78 74.04-2.37 92.95-35.44 23.63-74.83-21.27-57.5-65.38 11.82-31.51 36.24-42.54 59.87-27.57m107.91 9.45c-26 10.24-42.54 39.39-42.54 74.83 0 74.04 88.22 93.74 122.09 27.57 21.27-40.96 10.24-85.86-25.2-101.61-22.06-10.24-31.51-10.24-54.35-.79m50.41 33.09c21.27 21.26-.79 85.07-29.93 85.07-10.24 0-39.39-29.15-39.39-40.18 0-11.81 16.54-41.74 26.78-48.05 13.39-8.66 32.3-7.08 42.54 3.16M44.92 137.07c-66.16 26.78-41.74 133.91 29.94 133.91 44.89 0 70.1-57.5 46.47-103.19-7.09-11.82-48.05-40.17-56.71-37.81-1.58 0-10.24 3.15-19.7 7.09M78.79 167c14.97 9.46 26.79 39.39 22.06 52.78-9.45 23.63-33.08 29.14-50.41 11.81-32.3-32.29-7.88-89.01 28.35-64.59m320.59 59.87c-29.14 25.2-30.72 77.98-3.15 100.03 62.23 50.41 148.09-49.62 90.59-106.33-18.12-18.91-61.44-15.76-87.44 6.3m66.96 14.17c20.48 20.48 0 69.32-29.94 69.32-31.5 0-41.74-37.02-15.75-63.02 18.12-18.11 32.29-19.69 45.69-6.3m-290.66-7.09C138.66 258.37 66.19 367.86 66.19 398.58c0 16.54 7.88 31.51 26.78 50.41 28.36 28.36 40.96 31.51 83.5 18.91 37.02-11.82 85.85-11.03 124.45.79 56.71 16.54 103.98-15.76 103.98-71.68 0-38.6-74.84-149.67-112.64-167.78-39.39-18.91-84.28-17.33-116.58 4.72m118.15 33.88c31.51 25.99 79.56 106.33 79.56 131.54 0 33.08-21.27 47.26-58.29 40.17-42.54-7.88-119.73-7.88-156.75.79-34.66 7.09-60.65-10.24-60.65-40.17 0-22.85 63.01-122.88 87.43-140.21 29.15-19.69 80.35-16.54 108.7 7.88"
        />
      </svg>
    )}
  />
);

export default PawIcon;
