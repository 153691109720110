import { Tag } from "antd";

interface IYesNoTagProps {
  status?: boolean;
}

const YesNoTag = (props: IYesNoTagProps) => {
  return (
    <Tag color={props.status ? "green" : "red"}>
      {props.status ? "Evet" : "Hayır"}
    </Tag>
  );
};

export default YesNoTag;
