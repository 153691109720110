import { useContext } from "react";
import { VaccineCrudApi } from "../../client/Api";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getVaccineCPColumns,
  getVaccineCPFormItems,
} from "../../components/hook/VaccineCPComponent";
import { ConstantContext } from "../../context";

const VaccineCrudPage = () => {
  const constants = useContext(ConstantContext);

  return (
    <CrudTable
      entityLabel="Aşı"
      api={VaccineCrudApi}
      columns={getVaccineCPColumns(constants)}
      addFormItems={getVaccineCPFormItems(constants)}
      editFormItems={getVaccineCPFormItems(constants, true)}
      setEditFields={(row) => {
        return {
          ...row,
          racial: row.racial
            ? { label: row.racial.name, value: row.racial["@id"] }
            : undefined,
        };
      }}
      beforeEditOperation={(values: any) => {
        let racial = null;
        if (values.racial) {
          racial =
            typeof values.racial === "object"
              ? values.racial.value
              : values.racial;
        }

        return { ...values, racial: racial };
      }}
    />
  );
};

export default VaccineCrudPage;
