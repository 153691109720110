import { useParams } from "react-router-dom";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getStockCPColumns,
  getStockCPFormItems,
} from "../../components/hook/StockCPComponent";
import { getCrudApi } from "../../client/CrudApi";
import { useContext } from "react";
import { ConstantContext } from "../../context";
import dayjs from "dayjs";

const StockCrudPage = () => {
  const { id } = useParams();
  const constants = useContext(ConstantContext);

  return (
    <CrudTable
      hideSearchBar
      entityLabel="Stok Hareketi"
      api={getCrudApi("products/" + id + "/stocks")}
      columns={getStockCPColumns(constants)}
      addFormItems={getStockCPFormItems(constants)}
      editFormItems={getStockCPFormItems(constants, true)}
      setEditFields={(row) => ({
        ...row,
        id: row["@id"],
        expirationDate: row.expirationDate
          ? dayjs(row.expirationDate)
          : undefined,
        customer: row.customer
          ? { label: row.customer.name, value: row.customer["@id"] }
          : undefined,
      })}
      beforeAddOperation={(values) => ({
        ...values,
        expirationDate: dayjs(values.expirationDate).add(3, "h"),
      })}
      beforeEditOperation={(values: any) => {
        let customer = null;
        if (values.customer) {
          customer =
            typeof values.customer === "object"
              ? values.customer.value
              : values.customer;
        }

        //Direk edit seçilince Tarih + 00.00 olarak geliyor bu yüzden +3 eklememiz birşey ifade etmiyor
        //Eğer datepicker'dan seçersek Tarih-1 + 21:00 olarak geliyor mecburen +3 eklememiz lazım
        return {
          ...values,
          customer: customer,
          expirationDate: dayjs(values.expirationDate).add(3, "h"),
        };
      }}
    />
  );
};

export default StockCrudPage;
