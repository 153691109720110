import { Row, Image } from "antd";

const NotFoundPage = () => {
  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Image preview={false} width={512} height={512} src="/assets/404.png" />
    </Row>
  );
};

export default NotFoundPage;
