import { Grid, Layout, Menu, Row, Image, Drawer, Badge, Spin } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
import {
  HomeOutlined,
  UserOutlined,
  ProductOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GeneralApi } from "../../client/Api";
import PawIcon from "../../icons/PawIcon";
import { ClinicContext } from "../../context";

const { useBreakpoint } = Grid;
const { Sider } = Layout;

interface ISideMenuProps {
  drawerCollapsed: boolean;
  setDrawerCollapsed: (drawerCollapsed: boolean) => void;
}

const SideMenu = (props: ISideMenuProps) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedClinic } = useContext(ClinicContext);

  const [criticStockCountLoading, setCriticStockCountLoading] = useState(false);
  const [soonExpiredCountLoading, setSoonExpiredCountLoading] = useState(false);
  const [criticStockCount, setCriticStockCount] = useState(0);
  const [soonExpiredCount, setSoonExpiredCount] = useState(0);

  useEffect(() => {
    setSoonExpiredCountLoading(true);
    setCriticStockCountLoading(true);

    if (!selectedClinic) return;

    GeneralApi.getCriticStockCount()
      .then((response) => setCriticStockCount(response.count))
      .finally(() => setCriticStockCountLoading(false));

    GeneralApi.getSoonExpiredCountCount()
      .then((response) => setSoonExpiredCount(response.count))
      .finally(() => setSoonExpiredCountLoading(false));
  }, [selectedClinic]);

  const menuItems: ItemType<MenuItemType>[] = [
    {
      label: "Dashboard",
      key: "/",
      icon: <HomeOutlined />,
    },
    {
      label: "Cariler",
      key: "/cariler",
      icon: <UserOutlined />,
    },
    {
      label: "Hastalar",
      key: "hastalar",
      icon: <PawIcon />,
      children: [
        {
          label: "Hasta Listesi",
          key: "/hasta-listesi",
        },
        {
          label: "Tür/Irk Listesi",
          key: "/tur-irk-listesi",
        },
        {
          label: "Aşı Takvimi",
          key: "/asi-takvimi",
        },
        {
          label: "Aşı Listesi",
          key: "/asi-listesi",
        },
      ],
    },
    {
      label: "Ürünler",
      key: "urunler",
      icon: <ProductOutlined />,
      children: [
        {
          label: "Ürünler",
          key: "/urunler",
        },
        {
          label: (
            <>
              Kritik Stoklar
              {criticStockCountLoading ? (
                <Spin style={{ marginLeft: 8 }} size="small" />
              ) : (
                <Badge style={{ marginLeft: 8 }} count={criticStockCount} />
              )}
            </>
          ),
          key: "/kritik-stoklar",
        },
        {
          label: (
            <>
              SKT Uyarıları
              {soonExpiredCountLoading ? (
                <Spin style={{ marginLeft: 8 }} size="small" />
              ) : (
                <Badge style={{ marginLeft: 8 }} count={soonExpiredCount} />
              )}
            </>
          ),
          key: "/skt-uyarilari",
        },
        {
          label: "Kategoriler",
          key: "/kategoriler",
        },
        {
          label: "Markalar",
          key: "/markalar",
        },
      ],
    },
    {
      label: "Muhasebe",
      key: "muhasebe",
      icon: <DollarOutlined />,
      children: [
        {
          label: "Kasalar",
          key: "/kasalar",
        },
        {
          label: "Faturalar",
          key: "/faturalar",
        },
        {
          label: "Senet Bildirimleri",
          key: "/senet-bildirimleri",
        },
      ],
    },
  ];

  const content = (
    <>
      <Row justify="center" align="middle" style={{ height: 64 }}>
        <Image
          width={48}
          height={48}
          src="/assets/primary-circle-logo.png"
          alt="primary-logo"
          preview={false}
        />
      </Row>
      <Menu
        selectedKeys={[location?.pathname]}
        mode="inline"
        items={menuItems}
        onClick={({ key }) => navigate(key)}
      />
    </>
  );

  return screens.md ? (
    <Sider
      defaultCollapsed
      theme="light"
      collapsible
      width={240}
      collapsedWidth={64}
      style={{
        zIndex: 100,
        boxShadow: "rgba(19, 25, 32, 0.2) 0px 8px 8px",
      }}
    >
      {content}
    </Sider>
  ) : (
    <Drawer
      closable={false}
      width={300}
      placement="left"
      open={props.drawerCollapsed}
      onClose={() => props.setDrawerCollapsed(!props.drawerCollapsed)}
    >
      {content}
    </Drawer>
  );
};

export default SideMenu;
