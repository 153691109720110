import CrudTable from "../../components/common/crud/CrudTable";
import { Typography } from "antd";
import { getCriticalStockCPColumns } from "../../components/hook/CriticalStockCPComponent";
import { getCrudApi } from "../../client/CrudApi";

const { Text } = Typography;

const CriticalStockCrudPage = () => {
  return (
    <CrudTable
      api={{}}
      getAll={getCrudApi("/critical-stocks").getAll}
      hideAddProcess
      hideDefaultRowProceses
      hideSearchBar
      columns={getCriticalStockCPColumns()}
      customTableTitle={() => (
        <Text style={{ fontWeight: 600 }}>Kritik Stoklar</Text>
      )}
    />
  );
};

export default CriticalStockCrudPage;
