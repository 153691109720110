import { getFormattedDate } from "../../helpers/UtilHelper";

export const getSoonExpireCPColumns = () => {
  return [
    {
      title: "Ürün",
      dataIndex: "root",
      key: "product",
      render: (value: any) => value.product.name,
    },
    {
      title: "Seri No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (value: any) => value ?? "Yok",
    },
    {
      title: "SKT",
      dataIndex: "expirationDate",
      key: "expirationDate",
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY") : "Yok",
    },
    { title: "Adet/Miktar", dataIndex: "totalStock", key: "totalStock" },
  ];
};
