import { Col, Form, Input, InputNumber, Row, Select, Tooltip } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import SearchSelect from "../common/SearchSelect";
import { ProductCrudApi } from "../../client/Api";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import BKDatePicker from "../common/BKDatePicker";
import LimitedText from "../common/LimitedText";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useWatch } from "antd/es/form/Form";

interface IInvoiceFormListItemProps {
  name?: any;
  type?: any;
  form?: any;
  remove?: any;
}

const InvoiceFormListItem = (props: IInvoiceFormListItemProps) => {
  const rootForm = useWatch("invoiceItems", props.form);
  const [groupedStocks, setGroupedStocks] = useState<any[]>([]);

  const setStocks = (stocks?: any[]) => {
    if (stocks === undefined) {
      setGroupedStocks([]);
    } else {
      setGroupedStocks(
        stocks.map((groupedStock: any) => ({
          label:
            (groupedStock.serialNo ?? "-") +
            " / " +
            (groupedStock.expirationDate ?? "-") +
            " (" +
            groupedStock.totalStock +
            " Adet)",
          value: groupedStock.serialNo,
          entity: groupedStock,
        }))
      );
    }
  };

  useEffect(() => {
    const product = rootForm?.[props.name]?.["product"];
    if (!product || typeof product !== "object") return;

    setStocks(product?.["entity"]?.["groupedStocks"]);
  }, [rootForm, props.name]);

  return (
    <Row align="middle" gutter={[8, 8]}>
      <Col span={props.type === "sell" ? 6 : 5}>
        <Form.Item
          label="Ürün"
          name={[props.name, "product"]}
          style={{ width: "100%" }}
          rules={[required]}
        >
          <SearchSelect
            size="large"
            placeholder="Ürün seçiniz"
            searchAction={(searchValue, setOptions, setLoading) =>
              ProductCrudApi.getAll(1, 10, searchValue)
                .then((response) => {
                  setOptions(
                    getOptionsFromEntities(response["hydra:member"]).map(
                      (item: any) => ({
                        ...item,
                        label: (
                          <Row justify="space-between">
                            <LimitedText text={item.label} />
                            <Tooltip
                              title={
                                <Row>
                                  <Col span={24}>
                                    Ortalama Alış Fiyatı:{" "}
                                    {item.entity.averageBuyPrice ?? "0.00"}₺
                                  </Col>
                                  <Col span={24}>
                                    Son Alış Fiyatı:{" "}
                                    {item.entity.lastBuyPrice ?? "0.00"}₺
                                  </Col>
                                  <Col span={24}>
                                    Satış Fiyatı:{" "}
                                    {item.entity.sellPrice ?? "0.00"}₺
                                  </Col>
                                  <Col span={24}>
                                    Satış Fiyatı (2):{" "}
                                    {item.entity.sellPrice2 ?? "0.00"}₺
                                  </Col>
                                </Row>
                              }
                            >
                              <EyeOutlined style={{ cursor: "pointer" }} />
                            </Tooltip>
                          </Row>
                        ),
                      })
                    )
                  );
                })
                .finally(() => setLoading(false))
            }
            onSelect={(_, option) => {
              props.form.setFieldValue(
                ["invoiceItems", props.name, "price"],
                option.entity.sellPrice
              );
              props.form.setFieldValue(
                ["invoiceItems", props.name, "taxRate"],
                option.entity.tax
              );

              if (props.type === "sell") {
                props.form.resetFields([
                  ["invoiceItems", props.name, "serialNo"],
                  ["invoiceItems", props.name, "expirationDate"],
                ]);
              }

              props.form.resetFields([
                ["invoiceItems", props.name, "selectStock"],
              ]);
              setStocks(option.entity.groupedStocks);
            }}
          />
        </Form.Item>
      </Col>
      {props.type === "sell" && (
        <Col span={5}>
          <Form.Item
            label="Stok Seçiniz"
            name={[props.name, "selectStock"]}
            style={{ width: "100%" }}
            rules={[required]}
          >
            <Select
              size="large"
              options={groupedStocks}
              placeholder="Stok seçiniz"
              onChange={(_, option: any) => {
                props.form.setFieldValue(
                  ["invoiceItems", props.name, "serialNo"],
                  option.entity.serialNo
                );
                props.form.setFieldValue(
                  ["invoiceItems", props.name, "expirationDate"],
                  dayjs(option.entity.expirationDate, "DD.MM.YYYY")
                );
              }}
            />
          </Form.Item>
        </Col>
      )}
      {props.type === "buy" ? (
        <>
          <Col span={3}>
            <Form.Item
              label="Seri No"
              name={[props.name, "serialNo"]}
              style={{ width: "100%" }}
              rules={[required]}
            >
              <Input size="large" placeholder="Seri no giriniz" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="SKT"
              name={[props.name, "expirationDate"]}
              style={{ width: "100%" }}
              rules={[required]}
            >
              <BKDatePicker size="large" placeholder="SKT seçiniz" />
            </Form.Item>
          </Col>
        </>
      ) : (
        <>
          <Form.Item
            hidden
            label="Seri No"
            name={[props.name, "serialNo"]}
            style={{ width: "100%" }}
            rules={[required]}
          >
            <Input size="large" placeholder="Seri no giriniz" />
          </Form.Item>
          <Form.Item
            hidden
            label="SKT"
            name={[props.name, "expirationDate"]}
            style={{ width: "100%" }}
            rules={[required]}
          >
            <BKDatePicker size="large" placeholder="SKT seçiniz" />
          </Form.Item>
        </>
      )}
      <Col span={2}>
        <Form.Item
          label="Miktar"
          name={[props.name, "quantity"]}
          style={{ width: "100%" }}
          rules={[required]}
          initialValue="1"
        >
          <InputNumber
            style={{ width: "100%" }}
            size="large"
            stringMode
            precision={2}
            min={0}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item
          label="B. Fiyat"
          name={[props.name, "price"]}
          style={{ width: "100%" }}
          rules={[required]}
          initialValue="0"
          tooltip="KDV Hariç Birim Fiyat (₺)"
        >
          <InputNumber
            style={{ width: "100%" }}
            size="large"
            stringMode
            precision={3}
            min={0}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item
          label="İsk."
          name={[props.name, "discount"]}
          style={{ width: "100%" }}
          rules={[required]}
          initialValue="0"
          tooltip="KDV hariç fiyata yapılan iskonto tutarı (₺)"
        >
          <InputNumber
            style={{ width: "100%" }}
            size="large"
            stringMode
            precision={3}
            min={0}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item
          label="KDV (%)"
          name={[props.name, "taxRate"]}
          style={{ width: "100%" }}
          rules={[required]}
          initialValue="0"
        >
          <InputNumber
            style={{ width: "100%" }}
            size="large"
            stringMode
            precision={3}
            min={0}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item
          label="KDV (₺)"
          name={[props.name, "taxAmount"]}
          style={{ width: "100%" }}
          rules={[required]}
          initialValue="0"
        >
          <InputNumber
            disabled
            style={{ width: "100%" }}
            size="large"
            stringMode
            precision={3}
            min={0}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item
          label="S. Tutarı"
          name={[props.name, "rowAmount"]}
          style={{ width: "100%" }}
          rules={[required]}
          initialValue="0"
          tooltip="KDV hariç satır tutarı (₺)"
        >
          <InputNumber
            disabled
            style={{ width: "100%" }}
            size="large"
            stringMode
            precision={3}
            min={0}
          />
        </Form.Item>
      </Col>
      <Col span={1}>
        <Row justify="center">
          <Tooltip title="Satırı Sil">
            <CloseOutlined
              style={{ fontSize: 20, color: "red" }}
              onClick={() => props.remove(props.name)}
            />
          </Tooltip>
        </Row>
      </Col>
    </Row>
  );
};

export default InvoiceFormListItem;
