import { Form, Input } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import OptionalRacialForm from "../form/OptionalRacialForm";

export const getRacialCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Tür Adı",
      dataIndex: "specie",
      key: "specie",
      render: (_: any, row: any) => (row?.specie ? row.specieName : row.name),
    },
    {
      title: "Irk Adı",
      dataIndex: "name",
      key: "name",
      render: (_: any, row: any) => (row?.specie ? row.name : ""),
    },
  ];
};

export const getRacialCPFormItems = (isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <OptionalRacialForm />
    </>
  );
};
