import { Form, Input, InputNumber } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import {
  getConstantValue,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import SearchSelect from "../common/SearchSelect";
import { BrandCrudApi, CategoryCrudApi } from "../../client/Api";
import OptionalTarbilForm from "../form/OptionalTarbilForm";
import TableSearchFilter from "../common/TableSearchFilter";
import CategorySearchSelect from "../form/CategorySearchSelect";
import BrandSearchSelect from "../form/BrandSearchSelect";

export const getProductCPColumns = (
  constants: any,
  hiddenColumns: string[]
) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Ürün Adı", dataIndex: "name", key: "name" },
    {
      title: "Barkod",
      dataIndex: "barcode",
      key: "barcode",
      render: (value: any) => value ?? "Yok",
    },
    {
      title: "Kategori",
      dataIndex: "category",
      key: "category.id",
      render: (value: any) => value?.name ?? "Yok",
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <CategorySearchSelect
            style={{ width: 250 }}
            optionValueKey="id"
            value={props.selectedKeys[0]}
            onSelect={(value: any) => props.setSelectedKeys([value])}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Marka",
      dataIndex: "brand",
      key: "brand.id",
      render: (value: any) => value?.name ?? "Yok",
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <BrandSearchSelect
            style={{ width: 250 }}
            optionValueKey="id"
            value={props.selectedKeys[0]}
            onSelect={(value: any) => props.setSelectedKeys([value])}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Adet",
      dataIndex: "stockCount",
      key: "stockCount",
      render: (value: any) => value ?? 0,
    },
    {
      title: "Satış Fiyatı",
      dataIndex: "sellPrice",
      key: "sellPrice",
      sorter: true,
    },
    {
      title: "Satış Fiyatı (2)",
      dataIndex: "sellPrice2",
      key: "sellPrice2",
      sorter: true,
    },
    {
      title: "Ort. Alış Fiyatı",
      dataIndex: "averageBuyPrice",
      key: "averageBuyPrice",
      render: (value: any) => value ?? "Yok",
    },
    {
      title: "Son Alış Fiyatı",
      dataIndex: "lastBuyPrice",
      key: "lastBuyPrice",
      render: (value: any) => value ?? "Yok",
    },
    {
      title: "KDV",
      dataIndex: "tax",
      key: "tax",
    },
    {
      title: "Kritik Stok",
      dataIndex: "criticalStock",
      key: "criticalStock",
    },
    {
      title: "Tarbil Kaydı",
      dataIndex: "tarbilType",
      key: "tarbilType",
      render: (value: any) =>
        value && constants
          ? getConstantValue(value, constants.tarbilTypes)
          : "Kayıtlı Değil",
    },
    {
      title: "Tarbil No",
      dataIndex: "tarbilNo",
      key: "tarbilNo",
      render: (value: any) => value ?? "Yok",
    },
  ].filter((column) => !hiddenColumns.includes(column.key));
};

export const getProductCPFormItems = (constants: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Ürün Adı"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Ürün adı giriniz" />
      </Form.Item>
      <Form.Item
        name="sellPrice"
        label="Satış Fiyatı"
        rules={[required]}
        initialValue="0"
      >
        <InputNumber
          style={{ width: "100%" }}
          stringMode
          precision={2}
          min={0}
          placeholder="Satış fiyatı giriniz"
        />
      </Form.Item>
      <Form.Item name="sellPrice2" label="Satış Fiyatı (2)" initialValue="0">
        <InputNumber
          style={{ width: "100%" }}
          stringMode
          precision={2}
          min={0}
          placeholder="2. Satış fiyatı giriniz"
        />
      </Form.Item>
      <Form.Item name="tax" label="KDV" rules={[required]} initialValue="0">
        <InputNumber
          style={{ width: "100%" }}
          stringMode
          precision={2}
          min={0}
          placeholder="KDV giriniz"
        />
      </Form.Item>
      <Form.Item
        name="criticalStock"
        label="Kritik Stok"
        rules={[required]}
        initialValue="1"
      >
        <InputNumber
          precision={2}
          stringMode
          min={0}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item name="barcode" label="Barkod" rules={[min(2), max(255)]}>
        <Input placeholder="Barkod giriniz" />
      </Form.Item>
      <Form.Item name="category" label="Kategori">
        <SearchSelect
          placeholder="Kategori seçiniz"
          searchAction={(searchValue, setOptions, setLoading) =>
            CategoryCrudApi.getAll(1, 10, searchValue)
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
      <Form.Item name="brand" label="Marka">
        <SearchSelect
          placeholder="Marka seçiniz"
          searchAction={(searchValue, setOptions, setLoading) =>
            BrandCrudApi.getAll(1, 10, searchValue)
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
      <OptionalTarbilForm tarbilTypes={constants?.tarbilTypes} />
    </>
  );
};
