import { Avatar, Badge, Dropdown, MenuProps, Row } from "antd";
import { colors } from "../../theme";
import {
  SettingOutlined,
  LockOutlined,
  LogoutOutlined,
  SwapOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { removeCredentials } from "../../helpers/AuthHelper";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { ClinicContext, UserContext } from "../../context";
import ProfileUpdateModal, {
  IProfileUpdateModalRefMethods,
} from "../modal/ProfileUpdateModal";
import PasswordUpdateModal, {
  IPasswordUpdateModalRefMethods,
} from "../modal/PasswordUpdateModal";
import { BellOutlined } from "@ant-design/icons";
import { GeneralApi } from "../../client/Api";

const ProfileMenu = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { selectedClinic } = useContext(ClinicContext);
  const [soonCheckCount, setSoonCheckCount] = useState<number>();

  const profileUpdateModalRef = useRef<IProfileUpdateModalRefMethods>(null);
  const passwordUpdateModalRef = useRef<IPasswordUpdateModalRefMethods>(null);

  const items: MenuProps["items"] = [
    {
      key: "0",
      onClick: () => profileUpdateModalRef.current?.setOpen(true),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>{user ? user?.fullName : <LoadingOutlined />}</span>
          <SettingOutlined />
        </Row>
      ),
    },
    {
      key: "1",
      onClick: () => passwordUpdateModalRef.current?.setOpen(true),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>Şifremi Güncelle</span>
          <LockOutlined />
        </Row>
      ),
    },
    {
      key: "2",
      onClick: () => navigate("/klinik-degistir"),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>Klinik Değiştir</span>
          <SwapOutlined />
        </Row>
      ),
    },
    {
      key: "3",
      danger: true,
      onClick: () => removeCredentials(),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>Çıkış Yap</span>
          <LogoutOutlined />
        </Row>
      ),
    },
  ];

  useEffect(() => {
    if (!selectedClinic) return;
    GeneralApi.getSoonCheckCount().then((response) =>
      setSoonCheckCount(response.count)
    );
  }, [selectedClinic]);

  return (
    <>
      <Badge offset={[-40, 8]} count={soonCheckCount ?? 0}>
        <Avatar
          onClick={() => navigate("/senet-bildirimleri")}
          size={40}
          style={{
            backgroundColor: colors.secondary,
            cursor: "pointer",
            marginRight: 8,
            paddingTop: 4,
          }}
        >
          {soonCheckCount !== undefined ? (
            <BellOutlined style={{ fontSize: 24 }} />
          ) : (
            <LoadingOutlined />
          )}
        </Avatar>
      </Badge>
      <Dropdown menu={{ items }}>
        <Avatar
          size={40}
          style={{ backgroundColor: colors.secondary, cursor: "pointer" }}
        >
          {user ? user?.initialLettersOfName : <LoadingOutlined />}
        </Avatar>
      </Dropdown>
      <ProfileUpdateModal ref={profileUpdateModalRef} />
      <PasswordUpdateModal ref={passwordUpdateModalRef} />
    </>
  );
};

export default ProfileMenu;
