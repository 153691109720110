import { VaccineCrudApi } from "../../client/Api";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";
import SearchSelect from "../common/SearchSelect";

const VaccineSearchSelect = (props: any) => {
  const { optionValueKey, ...rest } = props;

  return (
    <SearchSelect
      {...rest}
      size="large"
      placeholder="Aşı seçiniz"
      searchAction={(searchValue, setOptions, setLoading) =>
        VaccineCrudApi.getAll(1, 10, searchValue)
          .then((response) => {
            setOptions(
              getOptionsFromEntities(
                response["hydra:member"],
                "name",
                optionValueKey ?? "@id"
              )
            );
          })
          .finally(() => setLoading(false))
      }
    />
  );
};

export default VaccineSearchSelect;
