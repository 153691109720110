import { DatePicker, Tag } from "antd";
import { getFormattedDate } from "../../helpers/UtilHelper";
import TableSearchFilter from "../common/TableSearchFilter";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export const getNotificationCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Senet Başlığı", dataIndex: "name", key: "name" },
    {
      title: "Senet Tarihi",
      dataIndex: "date",
      key: "date",
      render: (value: any) => getFormattedDate(value, "DD.MM.YYYY HH:mm"),
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <RangePicker
            showTime
            value={props.selectedKeys?.map((selectedKey: any) =>
              dayjs(selectedKey)
            )}
            onChange={(dates) => {
              if (dates === null) return;
              props.setSelectedKeys(
                dates.map((date: any) =>
                  dayjs(date).format("YYYY-MM-DDTHH:mm:ss")
                )
              );
            }}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Okunma Durumu",
      dataIndex: "isRead",
      key: "isRead",
      filterMultiple: false,
      filters: [
        { text: "Okundu", value: 1 },
        { text: "Okunmadı", value: 0 },
      ],
      render: (value: any) => (
        <Tag color={value ? "green" : "red"}>
          {value ? "Okundu" : "Okunmadı"}
        </Tag>
      ),
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getFormattedDate(value, "DD.MM.YYYY HH:mm"),
    },
  ];
};
