import {
  Button,
  Checkbox,
  Col,
  Popover,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { ProductCrudApi } from "../../client/Api";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getProductCPColumns,
  getProductCPFormItems,
} from "../../components/hook/ProductCPComponent";
import { getFormattedDate } from "../../helpers/UtilHelper";
import { useContext, useState } from "react";
import { ConstantContext } from "../../context";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { ArrowsAltOutlined, ControlOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Text, Title } = Typography;

const ProductCrudPage = () => {
  const constants = useContext(ConstantContext);
  const navigate = useNavigate();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const hiddenColumnOptions = [
    { title: "No", key: "id" },
    { title: "Ürün Adı", key: "name" },
    { title: "Barkod", key: "barcode" },
    { title: "Kategori", key: "category.id" },
    { title: "Marka", key: "brand.id" },
    { title: "Adet", key: "stockCount" },
    { title: "Satış Fiyatı", key: "sellPrice" },
    { title: "Satış Fiyatı (2)", key: "sellPrice2" },
    { title: "Ort. Alış Fiyatı", key: "averageBuyPrice" },
    { title: "Son Alış Fiyatı", key: "lastBuyPrice" },
    { title: "KDV", key: "tax" },
    { title: "Kritik Stok", key: "criticalStock" },
    { title: "Tarbil Kaydı", key: "tarbilType" },
    { title: "Tarbil No", key: "tarbilNo" },
  ];

  return (
    <CrudTable
      entityLabel="Ürün"
      api={ProductCrudApi}
      columns={getProductCPColumns(constants, hiddenColumns)}
      addFormItems={getProductCPFormItems(constants)}
      editFormItems={getProductCPFormItems(constants, true)}
      extraTitleProcess={() => (
        <Popover
          content={
            <Checkbox.Group onChange={(values) => setHiddenColumns(values)}>
              <Row style={{ maxWidth: 150 }}>
                {hiddenColumnOptions.map((column, index) => (
                  <Col span={24} key={index}>
                    <Checkbox value={column.key}>{column.title}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          }
          placement="bottomRight"
          title="Kolonları Gizle"
          trigger="click"
          open={popoverOpen}
          onOpenChange={setPopoverOpen}
        >
          <Tooltip title="Kolonları Gizle">
            <Button
              icon={<ControlOutlined />}
              style={{ marginRight: "1em" }}
              shape="circle"
            />
          </Tooltip>
        </Popover>
      )}
      extraRowProcess={(row) => (
        <>
          <CrudTableProcessButton
            icon={<ArrowsAltOutlined />}
            tooltipText="Stok Hareketleri"
            onClick={() => navigate("/stok-detay/" + row.id)}
          />
        </>
      )}
      expandable={{
        expandedRowRender: (record: any) => (
          <>
            <Row>
              <Space>
                <Text strong>Oluşturulma Tarihi: </Text>
                <Text>
                  {getFormattedDate(record.createdAt, "DD.MM.YYYY HH:mm:ss")}
                </Text>
                <br />
                <Text strong>Son Değişiklik Tarihi: </Text>
                <Text>
                  {getFormattedDate(record.updatedAt, "DD.MM.YYYY HH:mm:ss")}
                </Text>
              </Space>
              <Col span={24}>
                <Row>
                  <Col span={2}>
                    <Row justify="center">
                      <Title level={5}>Seri No</Title>
                    </Row>
                  </Col>
                  <Col span={2}>
                    <Row justify="center">
                      <Title level={5}>SKT</Title>
                    </Row>
                  </Col>
                  <Col span={2}>
                    <Row justify="center">
                      <Title level={5}>Stok</Title>
                    </Row>
                  </Col>
                </Row>
                {record.groupedStocks.map((groupedStock: any) => (
                  <Row>
                    <Col span={2}>
                      <Row justify="center">
                        <Text>{groupedStock.serialNo ?? "-"}</Text>
                      </Row>
                    </Col>
                    <Col span={2}>
                      <Row justify="center">
                        <Text>{groupedStock.expirationDate ?? "-"}</Text>
                      </Row>
                    </Col>
                    <Col span={2}>
                      <Row justify="center">
                        <Text>{groupedStock.totalStock}</Text>
                      </Row>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </>
        ),
      }}
      setEditFields={(row) => {
        return {
          ...row,
          brand: row.brand
            ? { label: row.brand.name, value: row.brand["@id"] }
            : undefined,
          category: row.category
            ? { label: row.category.name, value: row.category["@id"] }
            : undefined,
        };
      }}
      beforeEditOperation={(values: any) => {
        let category = null;
        if (values.category) {
          category =
            typeof values.category === "object"
              ? values.category.value
              : values.category;
        }

        let brand = null;
        if (values.brand) {
          brand =
            typeof values.brand === "object"
              ? values.brand.value
              : values.brand;
        }

        return { ...values, category: category, brand: brand };
      }}
    />
  );
};

export default ProductCrudPage;
