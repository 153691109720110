import { TreeSelect } from "antd";
import { useEffect, useState } from "react";
import { RacialCrudApi } from "../../client/Api";

const RacialTreeSelect = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setLoading(true);
    RacialCrudApi.getAll(1, 1000)
      .then((response) =>
        setOptions(response["hydra:member"].filter((item: any) => !item.parent))
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <TreeSelect
      {...props}
      treeLine
      treeDefaultExpandAll
      loading={loading}
      treeData={options}
      placeholder="Tür/Irk seçiniz"
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
    />
  );
};

export default RacialTreeSelect;
